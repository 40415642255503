<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <ValidationProvider name="Component" vid="component_id" rules="required|min:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="component_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                           {{ $t('org_pro.choose_component') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="module.component_id"
                          id="component_id"
                          :options="componentList"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="defaultValue" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Module Name (En)" vid="module_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="module_name"
                        slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                           {{ $t('org_pro.module_name') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="module_name"
                          v-model="module.module_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Module Name (Bn)" vid="module_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="module_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                           {{ $t('org_pro.module_name_bn') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="module_name_bn"
                          v-model="module.module_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('org_pro.sorting_order')"
                      label-for="sorting_order"
                    >
                      <template v-slot:label>
                        {{ $t('org_pro.sorting_order')}} <span class="text-danger">*</span>
                      </template>
                      <input @change="checkSortOrder(module,$event)" :id="module.id" type="text" :value="module.sorting_order" class="form-control"/>
                    </b-form-group>
                    <ValidationProvider name="Organization list" vid="org_id" rules="required|min:1">
                      <b-form-group
                          label-cols-sm="4"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                           {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                        </template>
                          <b-form-checkbox-group
                            v-model="module.org_id"
                            :options="organizationList"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-checkbox-group>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{module}}</pre> -->
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import { moduleStore, moduleUpdate } from '../../api/routes'
import OrderCheckModule from '@/Utils/common'
export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      defaultValue: '',
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      module: {
        module_name: '',
        module_name_bn: '',
        component_id: '',
        org_id: [],
        org_module: []
      },
      componentList: [],
      organizationList: [],
      isSave: true,
      moduleListData: this.$store.state.commonObjCommonConfig.moduleList
    }
  },
  computed: {
    getOrgList () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getModuleData()
      // Object.freeze(tmp)
      this.module = tmp
    }
    this.getComponentlist()
    this.getOrganizationlist()
  },
  mounted () {
    core.index()
    // let orgIds = []
    // orgIds = this.module.org_module.map(item => {
    //     return item.org_id
    // })
    // this.module = Object.assign({}, this.module, { org_id: orgIds })
  },
  watch: {
    'module.component_id': function (newVal, oldVal) {
      this.getLastOrder()
    }
  },
  methods: {
    checkSortOrder: function (item, event) {
      item.old_serial = item.sorting_order
      item.sorting_order = event.target.value
      if (item.component_id) {
        const returnData = OrderCheckModule.OrderCheck(item, this.moduleListData, this.isSave, 'Module')
        this.moduleListData = returnData.moduleListData
        this.isSave = returnData.isSave
      }
    },
    getModuleData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.module.old_serial = this.module.sorting_order
      const returnData = OrderCheckModule.OrderCheck(this.module, this.moduleListData, this.isSave, 'Module')
      if (!returnData.isSave) {
        this.OrderCheckModule.errorMessageShow(this.module)
        return false
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.module.id) {
        result = await RestApi.putData(commonServiceBaseUrl, `${moduleUpdate}/${this.id}`, this.module)
      } else {
        result = await RestApi.postData(commonServiceBaseUrl, moduleStore, this.module)
      }

      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getComponentlist () {
      const listObject = this.$store.state.commonObjCommonConfig.componentList.filter(item => item.status === 0)
      this.componentList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getOrganizationlist () {
      const tmp = this.$store.state.commonObj.organizationProfileList.map(item => {
        if (this.$i18n.locale === 'en') {
          return { text: item.abbreviation, value: item.value }
        } else {
          return { text: item.abbreviation_bn, value: item.value }
        }
      })
      this.organizationList = tmp
    },
    getLastOrder: function () {
      if (!this.module.id) {
        const moduleListDataCheck = this.$store.state.commonObjCommonConfig.moduleList.filter(item => item.component_id === this.module.component_id)
        this.moduleListDataCheck.sort((a, b) => { return a.sorting_order - b.sorting_order })
        let lastOrder = 0
        moduleListDataCheck.forEach((element, key) => {
          lastOrder = element.sorting_order
        })
        this.module.sorting_order = lastOrder + 1
      }
    }
  }
}
</script>
