<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('org_pro.module') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="5" lg="5" xl="5">
            <b-form-group
              class="row"
              label-cols-sm="5"
              :label="$t('org_pro.component_namel')"
              label-for="component_id"
            >
              <b-form-select
                plain
                id="component_id"
                :options="componentList"
                v-model="search.component_id"
                placeholder=""
              >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="5" lg="5" xl="5">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('org_pro.module_namel')"
              label-for="module_name"
            >
              <b-form-input
                id="module_name"
                v-model="search.module_name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('org_pro.module') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(component_name)="data">
                      <span class="capitalize">{{ data.item.component.component_name }}</span>
                    </template>
                    <template v-slot:cell(component_name_bn)="data">
                      {{ data.item.component.component_name_bn }}
                    </template>
                    <template v-slot:cell(module_name)="data">
                      <span class="capitalize">{{ data.item.module_name }}</span>
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ data.item.org.org_name }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge" :class="data.item.status ? 'badge-danger' : 'badge-success'">{{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(sorting_order)="data">
                      <span class="capitalize">
                        <input @change="checkSortOrder(data.item,$event)"  type="number" :value="data.item.sorting_order"  style="width: 100px;margin:auto" class="form-control text-center"/>
                      </span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                    <template #custom-foot="">
                      <b-tr>
                        <b-th colspan="4"></b-th>
                        <b-th class="text-center">
                          <button class="btn btn-primary btn-sm" v-on:click="SubmitData()">
                            <i class="fa fa-paper-plane"></i>Submit
                          </button>
                        </b-th>
                        <b-th colspan="2"></b-th>
                      </b-tr>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import { mapGetters } from 'vuex'
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import { moduleList, moduleToggleStatus, orgProfileList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import OrderCheckModule from '@/Utils/common'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        module_name: '',
        component_id: 0
      },
      moduleId: 0,
      moduletid: 0,
      rows: [],
      organizations: [],
      datas: [],
      isSave: true,
      moduleListData: this.$store.state.commonObjCommonConfig.moduleList
    }
  },
  computed: {
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    componentList: function () {
      return this.authorizedComponents.map(item => {
        return { value: item.id, text: this.$i18n.locale === 'en' ? item.component_name : item.component_name_bn }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('org_pro.module_entry') : this.$t('org_pro.module_modify')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro.component_namel'), class: 'text-center' },
          { label: this.$t('org_pro.module_namel'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('org_pro.sorting_order'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'component_name_bn' },
          { key: 'module_name_bn' },
          { key: 'orgListBn' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'component_name' },
          { key: 'module_name' },
          { key: 'orgListEn' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    ...mapGetters({
      authorizedComponents: 'Auth/getAuthorizedComponents'
    })
  },
  watch: {
  },
  async created () {
    this.loadData()
    this.getOrganizationList()
  },
  mounted () {
  },
  methods: {
    checkSortOrder: function (item, event) {
      item.old_serial = item.sorting_order
      item.sorting_order = event.target.value
      if (item.component_id) {
        const returnData = OrderCheckModule.OrderCheck(item, this.moduleListData, this.isSave, 'Module')
        this.moduleListData = returnData.moduleListData
        this.isSave = returnData.isSave
      }
    },
    async getOrganizationList () {
      const result = await RestApi.getData(commonServiceBaseUrl, orgProfileList)
      if (result.success) {
        this.organizations = result.data.data
      }
    },
    async searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(commonServiceBaseUrl, moduleToggleStatus, item, 'common_config', 'moduleList')
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(commonServiceBaseUrl, moduleList, params).then(response => {
          if (response.success) {
            const orgList = response.data.data.map(item => {
              const tmpOrg = item.org.map(orgItem => {
                return orgItem.org_name
              })

              const tmpOrgbn = item.org.map(orgItem => {
                return orgItem.org_name_bn
              })

              const orgIds = item.org.map(orgItem2 => {
                return orgItem2.id
              })
              const oldSerial = { old_serial: item.sorting_order }
              return Object.assign({}, item, oldSerial, { org_id: orgIds }, { orgListEn: tmpOrg.join(', ') }, { orgListBn: tmpOrgbn.join(', ') })
            })
            this.$store.dispatch('setList', orgList)
            this.paginationData(response.data)
            const allData = this.$store.state.list
            this.datas = JSON.parse(JSON.stringify(allData))
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    async confirmData () {
      let result = null
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      result = await RestApi.postData(commonServiceBaseUrl, 'master-menu/change-serial-order/MasterModule', this.datas)
      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
        this.moduleListData = this.$store.state.commonObjCommonConfig.moduleList
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.loadData()
      }
    },
    async SubmitData () {
      this.$swal({
        title: this.$t('globalTrans.recommendentChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((resultSwal) => {
        if (resultSwal.isConfirmed) {
          this.confirmData()
        }
      })
    }
  }
}
</script>
